<template>
  <div class="container">
    <div class="banner">
      <van-swipe :autoplay="3000" indicator-color="white" class="swiper">
        <van-swipe-item @click="toLink(item)" v-for="item in bannerList" :key="item.id">
          <van-image class="swiper-img" :src="item.pic" /></van-swipe-item>
      </van-swipe>
    </div>
    <div class="main">
      <van-cell title="课程专栏" />
      <van-list v-model="listLoading" class="list" :finished="listFinished" :finished-text="listFinishedText" @load="onListLoad">
        <div v-for="item in list" :key="item.package_id" class="list-item" @click="onNavigator({path:'/content/detail?package_id='+item.package_id})">
          <div class="list-item-media">
            <van-image radius="5" class="list-item-media-img" :src="item.package_cover" />
          </div>
          <div class="list-item-inner">
            <div class="list-item-title">{{ item.package_name }}</div>
            <!-- <div class="list-item-brief van-multi-ellipsis--l2">{{ item.package_brief }}</div> -->
            <div class="list-item-bottom">
              <div class="list-item-bottom-hd">
                <van-icon class="mr5" class-prefix="icon" size="14" name="IndentOutline" />
                <span>{{ item.package_count }}</span>
              </div>

              <div v-if="item.package_is_bought" class="list-item-bottom-bg">
                已购买
              </div>

              <div v-if="!item.package_is_bought && item.package_price" class="list-item-bottom-bd">
                <span>￥</span>{{ item.package_price }}
              </div>

            </div>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>

import PageMixin from '@/mixins/page'
import { Swipe, SwipeItem } from 'vant'

export default {
  name: 'Content',
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },
  mixins: [PageMixin],
  data() {
    return {
      bannerList: [],
      list: [],
      listLoading: false,
      listFinished: false,
      listFinishedText: '没有更多了',
      pageSize: 10,
      pageCurrent: 1
    }
  },
  created() {
    this.getBanner()
  },
  methods: {
    getBanner() {
      this.$api.banner_index().then(res => {
        this.bannerList = res.data.map((item, index) => ({
          id: index,
          pic: item.image,
          link:item.link
        }))
      })
    },
    onListLoad() {
      this.onPage()
    },
    onPage() {
      this.$api.content_index({ 'per-page': this.pageSize, page: this.pageCurrent })
        .then(res => {
          this.listLoading = false
          this.list = Number(res._meta.currentPage) === 1 ? res.items : this.list.concat(res.items)
          this.listFinished = this.list.length >= res._meta.totalCount
          this.listFinishedText = this.list.length ? '没有更多了' : '暂无相关数据~'
          this.pageCurrent += 1
        })
    },
    toLink(item){
      window.location.replace(item.link)
    }
  }
}
</script>

<style lang="less" scoped>
  .container{

  }
  .swiper{
    &-img{
      width: 100%;
      height: 180px;
    }
  }

  .list{

  }

  .list-item{
    display: flex;
    padding: 10px 15px;
    background: #fff;
    &-media{
      margin-right: 10px;
      &-img{
        width: 140px;
        height: 65px;
      }
    }
    &-inner{
      flex: 1;
    }
    &-title{
      font-size: 14px;
      color: #333;  
      margin-bottom: 5px;
    }
    &-brief{
      min-height: 34PX;
      font-size: 12px;
      color: #666;
      margin-bottom: 5px;
    }
    &-bottom{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: #666;
      &-bd{
        font-size: 10px;
        color: #f44;
      }
      &-bg{
        font-size: 10px;
        color:#00DE93;
      }
    }
  }
</style>

